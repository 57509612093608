import { TabAction, Token } from "../types";
import { ChevronDown, Search } from "lucide-react";
import {
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  chakra,
  VStack,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { formatUnits, readableNumber } from "../utils/format";
import CloseButtonForModal from "./common/CloseButtonForModal";
import { useMintContext } from "../context/MintContext";
import { request, gql } from "graphql-request";
import { runesIndexerApi } from "@services/BitcoinCustomsService";
import { useMintTokens } from "../context/MintTokenContext";
import { useHubContext } from "../context/OmnityHubContext";

const SearchIcon = chakra(Search);

type MintToken = Token & { terms_amount?: bigint };

type MintTokenSelectorProps = {
  isBitfinity?: boolean;
};
export default function MintTokenSelector({
  isBitfinity = false,
}: MintTokenSelectorProps) {
  const [keyword, setKeyword] = useState("");
  const { onTabActionChange } = useHubContext();
  const { token, onTokenChange, mintChain, onAmountChange } = useMintContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [availableTokens, setAvailableTokens] = useState<MintToken[]>([]);
  const [checkingAvaliable, setCheckingAvaliable] = useState(false);

  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const boxHoverWrapperBg = useColorModeValue("gray.200", "gray.600");
  const { tokens } = useMintTokens();

  useEffect(() => {
    if (token?.token_id) {
      isRunesMintFinished(token.token_id.split("-")[2]!).then((result) => {
        onAmountChange(result.terms_amount ?? 0n);
      });
    }
  }, [token?.token_id]);

  useEffect(() => {
    if (mintChain && tokens.length) {
      setCheckingAvaliable(true);
      Promise.all(
        tokens.map(async (token) => {
          return isRunesMintFinished(token.token_id.split("-")[2]!);
        }),
      ).then((results) => {
        setAvailableTokens(
          tokens
            .map((t, idx) => ({
              ...t,
              terms_amount: results[idx].terms_amount,
            }))
            .filter((_, idx) => results[idx].mintable),
        );
        setCheckingAvaliable(false);
      });
    }
  }, [mintChain, tokens.length]);

  return (
    <>
      <HStack
        w="100%"
        bg={isBitfinity ? "bg.darkMain" : boxWrapperBg}
        _hover={{
          bg: isBitfinity ? "bg.darkHover" : boxHoverWrapperBg,
        }}
        cursor="pointer"
        py={2}
        px={2}
        borderRadius={4}
        justifyContent="space-between"
        onClick={onOpen}
      >
        <HStack>
          {token && <Avatar name={token.icon} src={token.icon} boxSize={8} />}
          <Text
            fontSize={16}
            fontWeight={token ? 600 : 400}
            lineHeight={token ? 1 : 2}
          >
            {token?.name ?? "Select Token"}
          </Text>
        </HStack>
        <ChevronDown />
      </HStack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Select token</ModalHeader>
          <CloseButtonForModal />
          <ModalBody p={0}>
            <HStack px={6} pb={2} cursor="pointer">
              <Text color={textColor} fontSize={14}>
                Can't find the Runes, add it{" "}
                <Text
                  display="inline"
                  cursor="pointer"
                  color="blue.400"
                  onClick={() => {
                    onTabActionChange?.(TabAction.AddRunes);
                    onClose();
                  }}
                >
                  here
                </Text>
                .
              </Text>
            </HStack>
            <HStack px={6}>
              <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search name"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </InputGroup>
            </HStack>

            <VStack
              maxH={300}
              overflowY="scroll"
              overflowX="hidden"
              overflowWrap="normal"
              willChange="transform"
              mt={2}
              gap={0}
            >
              {availableTokens
                .filter((t) => {
                  if (keyword === "") {
                    return true;
                  }
                  return t.name.toLowerCase().includes(keyword.toLowerCase());
                })
                .map((t, idx) => {
                  const isLast = idx === availableTokens.length - 1;
                  return (
                    <HStack
                      key={t.name}
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      py={2}
                      px={6}
                      cursor="pointer"
                      borderBottomLeftRadius={isLast ? 6 : 0}
                      borderBottomRightRadius={isLast ? 6 : 0}
                      borderBottomWidth={isLast ? 0 : 0.5}
                      borderBottomColor={borderColor}
                      _hover={{ bg: boxWrapperHoverBg }}
                      onClick={() => {
                        onTokenChange(t);
                        onAmountChange(t.terms_amount ?? 0n);
                        onClose();
                      }}
                    >
                      <HStack>
                        <Avatar name={t.symbol} src={t.icon} boxSize={10} />
                        <VStack gap={0} alignItems="flex-start">
                          <Text
                            fontWeight={600}
                            fontSize={16}
                            lineHeight={1}
                            isTruncated
                            maxW={260}
                          >
                            {t.name}
                          </Text>
                          <Text
                            color="#999"
                            fontSize={12}
                            maxW={160}
                            isTruncated
                          >
                            {t.id}
                          </Text>
                        </VStack>
                      </HStack>
                      <Text fontWeight={600} fontSize={20}>
                        {readableNumber(formatUnits(t.balance, t.decimals))}
                      </Text>
                    </HStack>
                  );
                })}
              {availableTokens.length === 0 && !checkingAvaliable && (
                <Text mb={4} mt={2} color="gray.600">
                  No mintable tokens
                </Text>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

async function isRunesMintFinished(spaced_rune: string) {
  try {
    const document = gql`
      {
        runes(where: { spaced_rune: { _eq: "${spaced_rune}" } }) {
          rs_rune_stats {
            finished
            mintable
            runes {
              terms_amount
            }
          }
        }
      }
    `;
    const res = await request(`${runesIndexerApi}/v1/graphql`, document);

    const result = (res as any).runes.map((item: any) => {
      return {
        mintable: !item.rs_rune_stats.finished && item.rs_rune_stats.mintable,
        terms_amount: BigInt(item.rs_rune_stats.runes.terms_amount ?? 0),
      };
    });

    return result.length > 0 ? result[0] : false;
  } catch (error) {
    return {
      mintable: false,
      terms_amount: BigInt(0),
    };
  }
}
