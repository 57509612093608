import {
  BridgeFee,
  BridgeStep,
  Chain,
  ChainID,
  GenerateTicketResult,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  Token,
} from "../types";

export default abstract class BaseService {
  chain: Chain;

  constructor(chain: Chain) {
    this.chain = chain;
  }

  getTokenList(): Promise<Token[]> {
    return Promise.resolve([]);
  }

  fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    return Promise.resolve([]);
  }

  abstract getBridgeSteps(token?: Token): BridgeStep[];

  abstract onBridge(params: OnBridgeParams): Promise<string>;

  abstract onBurn(params: OnBurnParams): Promise<string>;

  abstract onMint(params: OnBridgeParams): Promise<string>;

  abstract generateTicket(ticket: Ticket): Promise<GenerateTicketResult>;

  abstract getTicketStatus(ticket_id: string): Promise<TicketStatusResult>;

  abstract getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee>;
}
