import {
  idlFactory as TONRouteInterfaceFactory,
  _SERVICE,
} from "./candids/TonRoute.did";
import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  Chain,
  TxStatus,
  GenerateTicketResult,
  AssetType,
} from "@types";
import BaseService from "./BaseService";
import { ActorSubclass } from "@dfinity/agent";
import { createActor } from "./candids";
import posthog from "posthog-js";
import { BTC_ICON, DEFAULT_TOKEN } from "src/utils/constants";

export default class TonRouteService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  static TON_INDEXER_API = "https://toncenter.com/api";
  static TON_API_KEY =
    "bdc644bbda2fdd4e2c609bffff8e37c05544ac225b76ed471060b6bc8c0d20b1";

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      TONRouteInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    if (this.chain.asset_type === AssetType.ckbtc) {
      const token: Token = {
        id: "EQD3IJCxBHFRNCFFLmtnoIyMEYt_Zio3WT0YQQujA2tSuCTZ",
        name: "BTC",
        symbol: "BTC",
        decimals: 8,
        icon: BTC_ICON,
        balance: 0n,
        token_id: DEFAULT_TOKEN[ChainID.BitcoinckBTC],
        fee: 0n,
        chain_id: ChainID.Ton,
      };

      return Promise.resolve([token]);
    }
    try {
      const tokenList = await this.actor.get_token_list();

      const tokens = tokenList.map((t) => {
        const [id] = t.ton_contract;
        return {
          id,
          name: t.token_id.split("-")[2],
          symbol: t.symbol,
          icon: t.icon[0],
          decimals: t.decimals,
          token_id: t.token_id,
          chain_id: this.chain.chain_id,
          balance: 0n,
          fee: 0n,
        };
      });
      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return [];
    }
  }

  async fetchTokens(
    token_ids?: string[] | undefined,
    address?: string | undefined,
  ): Promise<Token[]> {
    try {
      let tokenList = this.chain.token_list || [];

      if (Array.isArray(token_ids) && token_ids.length > 0) {
        tokenList = token_ids
          .map((id) => tokenList.find((r) => r.token_id === id))
          .filter((t) => !!t) as any;
      }
      if (!address) {
        return tokenList;
      }

      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const res = await fetch(
              `${TonRouteService.TON_INDEXER_API}/v3/jetton/wallets?owner_address=${address}&jetton_address=${t.id}&limit=10&offset=0`,
              {
                method: "GET",
                headers: new Headers({
                  "X-Api-Key": TonRouteService.TON_API_KEY,
                }),
              },
            ).then((r) => r.json());

            if (res.jetton_wallets?.length > 0) {
              const balance = BigInt(res.jetton_wallets[0].balance);
              return {
                ...t,
                balance,
                extra: { jettonWallet: res.jetton_wallets[0].address },
              };
            }
            return t;
          } catch (error) {
            return t;
          }
        }),
      );

      return tokens;
    } catch (error) {
      return [];
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Burn",
        description: "Burn jettons",
      },
      {
        title: "Bridge",
        description: "Generate Ticket",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      targetChainId,
      token,
      sourceAddr,
      transfer,
      setStep,
      targetAddr,
      amount,
    } = params;

    const [[fee], depositAddr] = await this.actor.get_fee(targetChainId);

    if (!fee) {
      throw new Error("Fee is not available");
    }
    if (!transfer) {
      throw new Error("transfer is required");
    }

    const message_hash = await transfer({
      depositAddr,
      fee,
      amount,
      jettonWalletAddr: token.extra.jettonWallet,
      sender: sourceAddr,
      targetChainId,
      receiver: targetAddr,
    });

    setStep && setStep(1);

    return message_hash;
  }

  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    const { amount, token, sender, receiver, dst_chain, ticket_id } = ticket;
    if (!ticket_id) {
      throw new Error("ticket_id is required");
    }
    const tx_hash = await TonRouteService.getTxHashFromMessageHash({
      address: sender,
      message_hash: ticket_id,
    });
    if (!tx_hash) {
      throw new Error("tx_hash is required");
    }
    const ticketParams = {
      amount: BigInt(amount),
      token_id: token,
      sender,
      target_chain_id: dst_chain,
      tx_hash,
      receiver,
    };
    const ticketResult = await this.actor.generate_ticket(ticketParams);
    console.log("###", ticketResult, tx_hash);

    const finializedTicket = {
      ...ticket,
      new_ticket_id: tx_hash,
      finalized: true,
    };
    if ("Err" in ticketResult) {
      posthog.capture("ticket generate error", {
        ...ticketParams,
        error: ticketResult.Err,
      });
      if (
        ticketResult.Err.includes("duplicate") ||
        ticketResult.Err.includes("already exists")
      ) {
        return { ticket: finializedTicket };
      }
      return {
        message: ticketResult.Err,
        ticket: { ...ticket, finalized: false },
      };
    }
    posthog.capture("ticket generate ok", {
      ...ticketParams,
    });
    return { ticket: finializedTicket };
  }

  static async getTxHashFromMessageHash(params: {
    address: string;
    message_hash: string;
  }) {
    const { address, message_hash } = params;
    const res = await fetch(
      `${TonRouteService.TON_INDEXER_API}/v3/events?address=${address}&msg_hash=${encodeURIComponent(message_hash)}&limit=10&offset=0&sort=desc`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          accept: "application/json",
          "X-Api-Key": TonRouteService.TON_API_KEY,
        }),
      },
    ).then((r) => r.json());
    if (res.events.length > 0) {
      const event = res.events[0];
      if (event.actions && event.actions.length > 0) {
        const burnAction = event.actions.find(
          (t: any) => t.type === "jetton_burn",
        );
        const feeAction = event.actions.find(
          (t: any) => t.type === "ton_transfer",
        );
        if (
          burnAction &&
          burnAction.success &&
          feeAction &&
          feeAction.success
        ) {
          return burnAction.trace_id;
        }
      }
    }
    return undefined;
  }

  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }

  static async getTxStatus(ticket: Ticket): Promise<TxStatus> {
    const tx_hash = await TonRouteService.getTxHashFromMessageHash({
      address: ticket.sender,
      message_hash: ticket.ticket_id!,
    });
    if (!tx_hash) {
      return "pending";
    }
    return "success";
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const _targetChainId =
      this.chain.asset_type === AssetType.ckbtc ? ChainID.sICP : targetChainId;
    const [[redeemFee]] = await this.actor.get_fee(_targetChainId);
    let fee = 0n;
    if (redeemFee !== undefined) {
      fee = redeemFee;
    }

    return {
      fee,
      symbol: "TON",
      decimals: 9,
    };
  }
}
