import { useBTCWalletKit } from "../../wallet-kits/btc-wallet-kit";
import { Button } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";
import { useICPWalletKit } from "../../wallet-kits/icp-wallet-kit";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useOSMWalletKit } from "@wallet-kits/osm-wallet-kit";
import { useTonWalletKit } from "@wallet-kits/ton-wallet-kit/TONWalletProvider";

export function TONConnectButton({ forSubmit }: { forSubmit: boolean }) {
  const { address, onShowModal } = useTonWalletKit();

  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={onShowModal}
      loadingText="Connecting"
      disabled={!!address}
    >
      Connect Ton Wallet
    </Button>
  );
}

export function OSMConnectButton({ forSubmit }: { forSubmit: boolean }) {
  const { address, onShowModal } = useOSMWalletKit();

  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={onShowModal}
      loadingText="Connecting"
      disabled={!!address}
    >
      Connect Keplr Wallet
    </Button>
  );
}

export function SOLConnectButton({ forSubmit }: { forSubmit: boolean }) {
  const { connected, connecting } = useWallet();
  const { setVisible } = useWalletModal();

  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={async () => {
        setVisible(true);
      }}
      disabled={!!connected}
      isLoading={connecting}
      loadingText="Connecting"
    >
      Connect Solana Wallet
    </Button>
  );
}

export function ICPConnectButton({ forSubmit }: { forSubmit: boolean }) {
  const { address, onShowModal } = useICPWalletKit();
  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={() =>
        onShowModal({
          whitelist: [],
        })
      }
      disabled={!!address}
    >
      Connect ICP Wallet
    </Button>
  );
}

export function EVMConnectButton({ forSubmit }: { forSubmit: boolean }) {
  const { address } = useEVMWalletKit();

  return (
    <ConnectButton.Custom>
      {({ openConnectModal }) => {
        return (
          <Button
            colorScheme="blue"
            w={forSubmit ? "98%" : "100%"}
            fontSize={forSubmit ? 24 : 18}
            py={forSubmit ? 8 : 6}
            borderRadius={8}
            onClick={openConnectModal}
            disabled={!!address}
          >
            Connect EVM Wallet
          </Button>
        );
      }}
    </ConnectButton.Custom>
  );
}

export function BTCConnectButton({ forSubmit }: { forSubmit: boolean }) {
  const { address, onShowModal } = useBTCWalletKit();
  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={() => onShowModal()}
      disabled={!!address}
    >
      Connect Bitcoin Wallet
    </Button>
  );
}
