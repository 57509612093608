import { BTCWalletProvider } from "@wallet-kits/btc-wallet-kit";
import { ICPWalletProvider } from "@wallet-kits/icp-wallet-kit";
import EthereumWaklletKit from "@wallet-kits/evm-wallet-kit";
import { SOLWalletProvider } from "@wallet-kits/sol-wallet-kit";
import { OSMWalletProvider } from "@wallet-kits/osm-wallet-kit";
import { ReactNode } from "react";
import TonWalletProvider from "./ton-wallet-kit/TONWalletProvider";

export default function WalletKitProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <BTCWalletProvider>
      <ICPWalletProvider>
        <EthereumWaklletKit>
          <SOLWalletProvider>
            <TonWalletProvider>
              <OSMWalletProvider>{children}</OSMWalletProvider>
            </TonWalletProvider>
          </SOLWalletProvider>
        </EthereumWaklletKit>
      </ICPWalletProvider>
    </BTCWalletProvider>
  );
}
