import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Text,
  useColorModeValue,
  Circle,
  usePrefersReducedMotion,
  HStack,
  chakra,
  VStack,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { PackageOpen, History } from "lucide-react";
import useTickets from "../hooks/useTickets";
import TicketItemComp from "./TicketItemComp";
import {
  ChainID,
  IndexerTicketStatus,
  Ticket,
  TicketAction,
  TicketItem,
} from "../types";
import { useHubContext } from "../context/OmnityHubContext";
import { useEffect, useMemo, useState } from "react";
import ServiceFactory from "../services/Factory";
import CloseButtonForModal from "./common/CloseButtonForModal";
import { BADGE_COLORS } from "src/utils/constants";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";

const HistoryIcon = chakra(History);
const PackageOpenIcon = chakra(PackageOpen);

const pulse = keyframes`
  from { box-shadow: 0 0 0 0px rgba(152, 92, 221, 0.5); }
  to { box-shadow: 0 0 0 10px rgba(152, 92, 221, 0); }
`;

const CATES_ALL = "All";
const CATES = [
  CATES_ALL,
  TicketAction.Transfer,
  TicketAction.Redeem,
  TicketAction.Mint,
  TicketAction.Burn,
];

export default function TicketHistory({
  isBitfinity,
}: {
  isBitfinity?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("gray.800", "gray.100");
  const [tickets, setTickets] = useState<TicketItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { tickets: localTickets, updateTicket } = useTickets();
  const { chains, addresses, customs } = useHubContext();
  const [ticketType, setTicketType] = useState(CATES_ALL);

  const onFinalize = async (ticket: Ticket) => {
    try {
      if (!ticket.ticket_id) {
        throw new Error("Ticket ID is required");
      }
      const _ticket = await fetchTicket(ticket.ticket_id!);
      if (_ticket && _ticket.status === IndexerTicketStatus.Finalized) {
        updateTicket({ ...ticket, finalized: true });
        return;
      }
      const chain = chains.find((c) => c.chain_id === ticket.src_chain);
      if (!chain) {
        throw new Error("Bitcoin chain not found");
      }
      const sourceService = ServiceFactory.createService(chain);
      const result = await sourceService?.generateTicket(ticket);
      if (result?.ticket.finalized) {
        updateTicket({ ...result.ticket });
      }
    } catch (error) {}
  };

  const _addresses = Object.values(addresses).filter((a) => a) as string[];
  useEffect(() => {
    if (_addresses.length) {
      setIsLoading(true);
      fetchTicketsByAddress(_addresses).then(({ tickets }) => {
        setIsLoading(false);
        setTickets(tickets);
      });
    }
  }, [localTickets.length, _addresses.length]);

  const nonfinalizedTickets = localTickets.filter((t) => !t.finalized);

  const someTicketNotFinalized = nonfinalizedTickets.length > 0;

  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${pulse} infinite 1s linear`;

  useEffect(() => {
    // transfer tickets
    localTickets
      .filter((t) => !t.finalized)
      .forEach((ticket) => {
        if (ticket.ticket_id) {
          const t = tickets.find((t) => t.ticket_id === ticket.ticket_id);
          if (t?.status === IndexerTicketStatus.Finalized) {
            updateTicket({ ...ticket, finalized: true });
            return;
          }

          ServiceFactory.getTxStatus(ticket).then((result) => {
            if (result === "success") {
              onFinalize(ticket);
            }
          });
        }
      });

    // mint tickets
    localTickets
      .filter((t) => t.type === TicketAction.Mint)
      .forEach((ticket) => {
        ServiceFactory.processMintTicket(ticket, chains)
          .then((result) => {
            if (result && result.finalized) {
              updateTicket({
                ...ticket,
                finalized: true,
                mint_tx_hash: result.tx_hash,
              });
            }
          })
          .catch(console.error);
      });
  }, [localTickets.length, tickets.length]);

  const _tickets = useMemo(() => {
    return tickets.filter((t) => {
      if (ticketType === CATES_ALL) {
        return true;
      }
      return t.action === ticketType;
    });
  }, [ticketType, tickets.length]);

  let tabs = CATES;
  if (customs === ChainID.sICP || customs === ChainID.BitcoinBrc20) {
    tabs = [TicketAction.Transfer];
  }

  return (
    <>
      <HStack
        gap={1}
        pos="absolute"
        right={{ base: isBitfinity ? 4 : 2, md: isBitfinity ? 4 : 0 }}
        top={{
          base: isBitfinity ? 6 : tabs.length === 1 ? 2 : -6,
          md: isBitfinity ? 6 : 2,
        }}
        cursor="pointer"
        color={isBitfinity ? "primary.main" : ""}
        onClick={onOpen}
      >
        <HistoryIcon size={18} />
        <Text textStyle="h5">History</Text>
        {someTicketNotFinalized && (
          <Circle
            size={2}
            animation={animation}
            bg="green.300"
            pos="absolute"
            right={-2}
            top={0}
          />
        )}
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <CloseButtonForModal />
          <ModalHeader>History</ModalHeader>
          <ModalBody w="100%" px={0}>
            <HStack px={4} pb={2}>
              {CATES.map((cate) => {
                return (
                  <Box
                    key={cate}
                    borderWidth={0.5}
                    px={4}
                    py={1}
                    borderRadius="full"
                    onClick={() => setTicketType(cate)}
                    cursor="pointer"
                    bg={
                      cate === ticketType
                        ? `${BADGE_COLORS[cate as TicketAction] ?? "teal"}.600`
                        : "transparent"
                    }
                    color={cate === ticketType ? "white" : ""}
                  >
                    <Text fontSize={14}>{cate}</Text>
                  </Box>
                );
              })}
            </HStack>
            <VStack w="100%" gap={0} h={400} overflowY="scroll">
              {isLoading && <Spinner size="lg" />}
              {nonfinalizedTickets.map((t) => {
                return (
                  <TicketItemComp
                    key={t.ticket_id}
                    local
                    ticket={{
                      ...t,
                      action: t.type!,
                      status: IndexerTicketStatus.Generating,
                      ticket_type: t.type!,
                      ticket_seq: -1,
                      memo: "",
                      ticket_id: t.ticket_id!,
                    }}
                  />
                );
              })}
              {_tickets.map((t) => {
                return <TicketItemComp key={t.ticket_id} ticket={t} />;
              })}
              {_tickets.length === 0 && (
                <VStack
                  py={4}
                  h="100%"
                  justifyContent="center"
                  color="gray.500"
                >
                  <PackageOpenIcon size={36} strokeWidth={1} />
                  <Text>
                    No{" "}
                    {ticketType === CATES_ALL ? "" : ticketType.toLowerCase()}{" "}
                    tickets
                  </Text>
                </VStack>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

async function fetchTicketsByAddress(
  addrs: string[],
): Promise<{ tickets: TicketItem[]; total: number }> {
  try {
    const addrFilter = addrs.map((addr) => {
      return `{receiver:{_iregex:"${addr}"}}, {sender:{_iregex:"${addr}"}}`;
    });
    const doc = gql`
      {
        ticket_aggregate(order_by: {ticket_time: desc},
          where: {
            _or: [${addrFilter.join(",")}]
        }) {
          aggregate {
            count
          }
        }
        ticket(
          order_by: {ticket_time: desc},
          where: {
            _or: [${addrFilter.join(",")}]
          }
          limit: 30
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      total: data.ticket_aggregate.aggregate.count,
      tickets: data.ticket,
    };
  } catch (error) {
    return {
      total: 0,
      tickets: [],
    };
  }
}

async function fetchTicket(id: string) {
  try {
    const doc = gql`
      {
        ticket(
          where: {
            ticket_id: {
              _eq: "${id}"
            }
          }
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
          intermediate_tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.ticket[0] as TicketItem;
  } catch (error) {
    return null;
  }
}
