import {
  OKX,
  OkxLogo,
  UNISAT,
  UnisatLogo,
  LEATHER,
  LeatherLogo,
  MAGIC_EDEN,
  MagicEdenLogo,
  // XVERSE,
  // XverseLogo,
  // ORANGE,
  ProviderType,
} from "@omnisat/lasereyes";
// import { OrangeLogo } from "./components/WalletLogos";

export const BTC_WALLETS: { type: ProviderType; logo: any; name: string }[] = [
  {
    type: OKX,
    logo: OkxLogo,
    name: "OKX",
  },
  {
    type: UNISAT,
    logo: UnisatLogo,
    name: "UniSat",
  },
  {
    type: LEATHER,
    logo: LeatherLogo,
    name: "Leather",
  },
  {
    type: MAGIC_EDEN,
    logo: MagicEdenLogo,
    name: "Magic Eden",
  },
  // {
  //   type: XVERSE,
  //   logo: XverseLogo,
  //   name: "Xverse",
  // },
  // {
  //   type: ORANGE,
  //   logo: OrangeLogo,
  //   name: "Orange",
  // },
];
