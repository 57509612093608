import { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Circle,
  Spinner,
  chakra,
  Text,
  Divider,
  Stack,
} from "@chakra-ui/react";
import { ChevronRight } from "lucide-react";
import ChainLogo from "../common/ChainLogo";
import { formatUnits, parseAmount, readableNumber } from "../../utils/format";
import { ChainID, ChainName, Token } from "@types";
import { useHubContext } from "../../context/OmnityHubContext";

const ChevronRightIcon = chakra(ChevronRight);

export default function ChainToChain({
  broadcasting,
  showAddr,
  isCompelted,
  sourceChain,
  targetChain,
  sourceAddr,
  targetAddr,
  amount,
  token,
  fee,
}: {
  broadcasting?: boolean;
  showAddr?: boolean;
  isCompelted: boolean;
  sourceChain?: ChainName;
  targetChain?: ChainName;
  sourceAddr?: string;
  targetAddr?: string;
  amount: string;
  token?: Token;
  fee?: string;
}) {
  const { customs, chains } = useHubContext();
  const [active, setActive] = useState(-1);

  useEffect(() => {
    if (broadcasting) {
      const listner = setInterval(() => {
        setActive((prev) => (prev + 1) % 4);
      }, 400);
      return () => clearInterval(listner);
    } else {
      setActive(-1);
    }
  }, [broadcasting]);

  const data = [
    {
      key: "Sender",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
          color="#008cd5"
        >
          {sourceAddr}
        </Text>
      ),
    },
    {
      key: "Receiver",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
          color="#008cd5"
        >
          {targetAddr}
        </Text>
      ),
    },
    {
      key: "Bridge Fee",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
        >
          {fee}
        </Text>
      ),
    },
  ];

  if (customs === ChainID.sICP) {
    const icrcToken = chains
      .find((t) => t.chain_id === ChainID.sICP)
      ?.token_list?.find((t) => t.token_id === token?.token_id);
    if (icrcToken && icrcToken.fee !== 0n) {
      if (sourceChain === ChainName.Bitfinity) {
        data.push({
          key: "Transaction Fee",
          value: (
            <Text
              fontWeight={600}
              inlineSize={300}
              textAlign={{ base: "left", md: "right" }}
            >
              {formatUnits(icrcToken.fee, icrcToken.decimals)}{" "}
              {icrcToken.symbol}
            </Text>
          ),
        });

        const receivedAmount =
          parseAmount(amount, icrcToken.decimals) - BigInt(icrcToken.fee);
        data.push({
          key: "You will receive",
          value: (
            <Text
              fontWeight={600}
              inlineSize={300}
              textAlign={{ base: "left", md: "right" }}
            >
              {formatUnits(receivedAmount, icrcToken.decimals)}{" "}
              {icrcToken.symbol}
            </Text>
          ),
        });
      }
    }
  }

  return (
    <VStack w="100%">
      <HStack gap={6}>
        <VStack>
          <Circle
            pos="relative"
            size={24}
            borderWidth={isCompelted ? 4 : 0}
            borderColor="green.500"
          >
            <ChainLogo chain={sourceChain} size={100} />
            {broadcasting && !isCompelted && (
              <Spinner
                pos="absolute"
                w="100%"
                height="100%"
                color="blue.500"
                speed="1s"
                emptyColor="gray.200"
                thickness="4px"
              />
            )}
          </Circle>
        </VStack>
        <VStack gap={2}>
          <HStack gap={0}>
            {new Array(4).fill(0).map((_, i) => {
              return (
                <ChevronRightIcon
                  key={i}
                  size={24}
                  strokeWidth={2}
                  color={isCompelted || active === i ? "blue.500" : "gray.200"}
                />
              );
            })}
          </HStack>
        </VStack>
        <VStack>
          <Circle pos="relative" size={24}>
            <ChainLogo chain={targetChain} size={100} />
          </Circle>
        </VStack>
      </HStack>
      <HStack gap={2} alignItems="flex-end">
        <Text fontWeight={600} fontSize={32} lineHeight={1}>
          {readableNumber(amount, token?.decimals)} {token?.symbol}
        </Text>
      </HStack>

      <Divider w="100%" my={2} />

      <VStack w="100%" alignItems="flex-start">
        {showAddr &&
          data.map((d) => {
            return (
              <Stack
                flexDir={{ base: "column", md: "row" }}
                key={d.key}
                w="100%"
                gap={0}
                justifyContent="space-between"
              >
                <Text w={{ base: "", md: 20 }} whiteSpace="nowrap" color="#999">
                  {d.key}
                </Text>
                <Text fontWeight={600}>{d.value}</Text>
              </Stack>
            );
          })}
      </VStack>
    </VStack>
  );
}
