import {
  Avatar,
  Badge,
  Box,
  HStack,
  Link,
  Skeleton,
  Text,
  VStack,
  chakra,
  useClipboard,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { IndexerTicketStatus, TicketAction, TicketItem } from "../types";
import { ArrowRight, Copy, CheckCheck, ArrowUpRight } from "lucide-react";
import { formatUnits, readableNumber } from "../utils/format";
import { ReactNode } from "react";
import dayjs from "dayjs";
import ChainLogo from "./common/ChainLogo";
import ServiceFactory from "@services/Factory";
import { BADGE_COLORS, TICKET_STATUS_COLORS } from "../utils/constants";
import relativeTime from "dayjs/plugin/relativeTime";
import { useHubContext } from "../context/OmnityHubContext";
dayjs.extend(relativeTime);

const CheckCheckIcon = chakra(CheckCheck);

const ticketStatusText = {
  [IndexerTicketStatus.Generating]: "Generating",
  [IndexerTicketStatus.Unknown]: "Pending",
  [IndexerTicketStatus.Pending]: "Pending",
  [IndexerTicketStatus.WaitingForConfirmBySrc]: "Sending",
  [IndexerTicketStatus.WaitingForConfirmByDest]: "Receiving",
  [IndexerTicketStatus.Finalized]: "Finalized",
};

export default function TicketItemComp({
  ticket,
  local = false,
}: {
  ticket: TicketItem;
  local?: boolean;
}) {
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");
  const { customs, chains } = useHubContext();
  const tokenList = chains.find(
    (chain) => chain.chain_id === customs,
  )?.token_list;
  const token = tokenList?.find((token) => token.token_id === ticket.token);
  if (!token) {
    return null;
  }

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      py={2}
      px={6}
      _hover={{
        bg: boxWrapperHoverBg,
      }}
      borderBottomWidth={0.5}
      borderBottomColor={boxWrapperHoverBg}
      gap={1}
      pos="relative"
    >
      {local && (
        <Skeleton
          pos="absolute"
          w="100%"
          h="100%"
          zIndex={-1}
          left={0}
          top={0}
        ></Skeleton>
      )}

      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.500" fontSize={14}>
          {formatTicketTime(ticket.ticket_time)}
        </Text>
        <HStack>
          <Badge colorScheme={BADGE_COLORS[ticket.action]}>
            {ticket.action}
          </Badge>
          <ChainInfo ticket={ticket} />
        </HStack>
      </HStack>

      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.400">Status</Text>
        <Text
          color={TICKET_STATUS_COLORS[ticket.status]}
          fontFamily="monospace"
          fontWeight={600}
        >
          {ticketStatusText[ticket.status]}
        </Text>
      </HStack>

      <Row
        title="ID"
        value={ticket.ticket_id ?? ""}
        isHash
        url={ServiceFactory.getTicketLink(ticket.ticket_id)}
      />
      <Row title="Sender" value={ticket.sender ?? ""} isHash />
      {ticket.action !== TicketAction.Burn && (
        <Row title="Receiver" value={ticket.receiver ?? ""} isHash />
      )}
      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.400">Token</Text>
        <HStack>
          <Text fontWeight={600}>
            {readableNumber(
              formatUnits(BigInt(ticket.amount), token.decimals),
              6,
            )}
          </Text>
          <Avatar size="xs" src={token.icon} name={token.symbol} />
        </HStack>
      </HStack>
    </VStack>
  );
}

function Row({
  title,
  value,
  isHash,
  url,
  leftAddon,
}: {
  title: string;
  value: string;
  isHash?: boolean;
  url?: string;
  leftAddon?: ReactNode;
}) {
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(value, 1000);

  let valueComp = (
    <Text
      title={value}
      color={isHash ? "blue.400" : ""}
      fontFamily={isHash ? "monospace" : ""}
    >
      {isHash ? `${value.slice(0, 12)}...${value.slice(-6)}` : value}
    </Text>
  );
  const isValidUrl = url?.startsWith("http");
  if (isValidUrl) {
    valueComp = (
      <Link href={url} target="_blank">
        <HStack>
          {valueComp}
          <ArrowUpRight size={16} />
        </HStack>
      </Link>
    );
  }
  return (
    <HStack w="100%" justifyContent="space-between">
      <Text color="gray.400">{title}</Text>
      <HStack>
        {leftAddon}
        {valueComp}
        {isHash && !isValidUrl && (
          <Box
            cursor="pointer"
            onClick={() => {
              onCopy();
              toast({
                title: "Copied to clipboard",
                status: "success",
                duration: 2000,
              });
            }}
          >
            {hasCopied ? (
              <CheckCheckIcon size={16} color="green.400" />
            ) : (
              <Copy size={16} />
            )}
          </Box>
        )}
      </HStack>
    </HStack>
  );
}

function ChainInfo({ ticket }: { ticket: TicketItem }) {
  if (
    ticket.action === TicketAction.Mint ||
    ticket.action === TicketAction.Burn
  ) {
    return <ChainLogo chain={ticket.src_chain} size={24} />;
  }
  if (
    ticket.action === TicketAction.Transfer ||
    ticket.action === TicketAction.Redeem
  ) {
    return (
      <HStack>
        <ChainLogo chain={ticket.src_chain} size={24} />
        <ArrowRight size={16} />
        <ChainLogo chain={ticket.dst_chain} size={24} />
      </HStack>
    );
  }
  return null;
}

function formatTicketTime(time: number) {
  const _time = Math.floor(time / 1000000);
  if (_time < 0) {
    return "-";
  }
  const _now = Date.now();
  if (_now - _time > 1000 * 60 * 60 * 24 * 15) {
    return dayjs(_time).format("MM-DD HH:mm:ss");
  }
  return dayjs(_time).fromNow();
}
