import {
  TonConnectUIProvider,
  useTonAddress,
  useTonConnectModal,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import { beginCell, Address, Cell } from "@ton/ton";

export const MANIFEST = `${window.location.origin}/tonconnect-manifest.json`;

export default function TonWalletProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <TonConnectUIProvider
      manifestUrl={MANIFEST}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: "tonwallet",
            name: "TON Wallet",
            imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
            aboutUrl:
              "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
            universalLink: "https://wallet.ton.org/ton-connect",
            jsBridgeKey: "tonwallet",
            bridgeUrl: "https://bridge.tonapi.io/bridge",
            platforms: ["chrome", "android"],
          },
          {
            appName: "nicegramWallet",
            name: "Nicegram Wallet",
            imageUrl: "https://static.nicegram.app/icon.png",
            aboutUrl: "https://nicegram.app",
            universalLink: "https://nicegram.app/tc",
            deepLink: "nicegram-tc://",
            jsBridgeKey: "nicegramWallet",
            bridgeUrl: "https://bridge.tonapi.io/bridge",
            platforms: ["ios", "android"],
          },
          {
            appName: "binanceWeb3TonWallet",
            name: "Binance Web3 Wallet",
            imageUrl:
              "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiBmaWxsPSIjMEIwRTExIi8+CjxwYXRoIGQ9Ik01IDE1TDcuMjU4MDYgMTIuNzQxOUw5LjUxNjEzIDE1TDcuMjU4MDYgMTcuMjU4MUw1IDE1WiIgZmlsbD0iI0YwQjkwQiIvPgo8cGF0aCBkPSJNOC44NzA5NyAxMS4xMjlMMTUgNUwyMS4xMjkgMTEuMTI5TDE4Ljg3MSAxMy4zODcxTDE1IDkuNTE2MTNMMTEuMTI5IDEzLjM4NzFMOC44NzA5NyAxMS4xMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMi43NDE5IDE1TDE1IDEyLjc0MTlMMTcuMjU4MSAxNUwxNSAxNy4yNTgxTDEyLjc0MTkgMTVaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMS4xMjkgMTYuNjEyOUw4Ljg3MDk3IDE4Ljg3MUwxNSAyNUwyMS4xMjkgMTguODcxTDE4Ljg3MSAxNi42MTI5TDE1IDIwLjQ4MzlMMTEuMTI5IDE2LjYxMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0yMC40ODM5IDE1TDIyLjc0MTkgMTIuNzQxOUwyNSAxNUwyMi43NDE5IDE3LjI1ODFMMjAuNDgzOSAxNVoiIGZpbGw9IiNGMEI5MEIiLz4KPC9zdmc+Cg==",
            aboutUrl: "https://www.binance.com/en/web3wallet",
            deepLink: "bnc://app.binance.com/cedefi/ton-connect",
            bridgeUrl: "https://bridge.tonapi.io/bridge",
            jsBridgeKey: "binancew3w",
            platforms: ["chrome", "safari", "ios", "android"],
            universalLink: "https://app.binance.com/cedefi/ton-connect",
          },
          {
            appName: "fintopio-tg",
            name: "Fintopio Telegram",
            imageUrl: "https://fintopio.com/tonconnect-icon.png",
            aboutUrl: "https://fintopio.com",
            universalLink: "https://t.me/fintopio?attach=wallet",
            bridgeUrl: "https://wallet-bridge.fintopio.com/bridge",
            platforms: ["ios", "android", "macos", "windows", "linux"],
          },
          {
            appName: "GateWallet",
            name: "GateWallet",
            imageUrl: "https://www.gate.io/images/login/qrcode_center_icon.svg",
            aboutUrl: "https://www.gate.io/",
            bridgeUrl: "https://dapp.gateio.services/tonbridge_api/bridge/v1",
            jsBridgeKey: "gatetonwallet",
            platforms: ["ios", "android", "chrome"],
            universalLink: "https://gateio.onelink.me/DmA6/web3",
          },
          {
            appName: "tokenpocket",
            name: "TokenPocket",
            imageUrl: "https://hk.tpstatic.net/logo/tokenpocket.png",
            aboutUrl: "https://tokenpocket.pro",
            jsBridgeKey: "tokenpocket",
            platforms: ["ios", "android"],
          },
          {
            appName: "bybitTonWallet",
            name: "Bybit Wallet",
            imageUrl:
              "https://static.bymj.io/bhop/image/Q3Kmzw7qczSZF5eqfo6pW8QuT1MDMmqC80lWxFBhiE0.png",
            aboutUrl: "https://www.bybit.com/web3",
            universalLink: "https://app.bybit.com/ton-connect",
            deepLink: "bybitapp://",
            bridgeUrl:
              "https://api-node.bybit.com/spot/api/web3/bridge/ton/bridge",
            jsBridgeKey: "bybitTonWallet",
            platforms: ["ios", "android", "windows", "linux", "macos"],
          },
          {
            appName: "dewallet",
            name: "DeWallet",
            imageUrl:
              "https://raw.githubusercontent.com/delab-team/manifests-images/main/WalletAvatar.png",
            aboutUrl: "https://delabwallet.com",
            universalLink: "https://t.me/dewallet?attach=wallet",
            bridgeUrl: "https://bridge.dewallet.pro/bridge",
            platforms: ["ios", "android", "macos", "windows", "linux"],
          },
        ],
      }}
      // actionsConfiguration={{
      //   twaReturnUrl: "https://t.me/DemoDappWithTonConnectBot/demo",
      // }}
    >
      {children}
    </TonConnectUIProvider>
  );
}

export function useTonWalletKit() {
  const _address = useTonAddress(true);
  const [tonConnectUI] = useTonConnectUI();

  const { open } = useTonConnectModal();

  const onDisconnect = async () => {
    await tonConnectUI.disconnect();
  };

  const transfer = async (params: any) => {
    // https://github.com/ton-org/ton/issues/44
    const {
      depositAddr,
      fee,
      amount,
      jettonWalletAddr,
      sender,
      receiver,
      targetChainId,
    } = params;
    const burnFee = 50000000n;
    // https://docs.rs/tonlib-core/latest/tonlib_core/message/constant.JETTON_BURN.html
    const JETTON_BURN = 0x595f07bc;

    const senderAddr = Address.parse(sender);
    const jettonAddr = Address.parseRaw(jettonWalletAddr);
    const customPayload = beginCell()
      .storeStringTail(
        JSON.stringify({
          target_chain: targetChainId,
          receiver,
        }),
      )
      .endCell();

    const timestamp = Math.floor(Date.now() / 1000);
    const burnBody = beginCell()
      .storeUint(JETTON_BURN, 32)
      .storeUint(timestamp, 64)
      .storeCoins(amount)
      .storeAddress(senderAddr)
      .storeUint(1, 1)
      .storeRef(customPayload)
      .endCell();

    const { boc } = await tonConnectUI.sendTransaction({
      validUntil: timestamp + 360,
      messages: [
        {
          address: jettonAddr.toString(),
          amount: burnFee.toString(),
          payload: burnBody.toBoc().toString("base64"),
        },
        {
          address: depositAddr,
          amount: fee.toString(),
        },
      ],
    });

    const messageHash = Cell.fromBase64(boc).hash().toString("base64");

    return messageHash;
  };

  return {
    address: _address,
    onShowModal: open,
    transfer,
    onDisconnect,
  };
}
